@font-face {
  font-family: Panton;
  font-weight: normal;
  src: url("https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/Fontfabric+-+Panton.otf") format("opentype");
}

@font-face {
  font-family: Panton;
  font-weight: bold;
  src: url("https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/Fontfabric+-+Panton+SemiBold.otf") format("opentype");
}

@font-face {
  font-family: Panton;
  font-weight: 800;
  src: url("https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/Fontfabric+-+Panton+Bold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: Panton, Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
