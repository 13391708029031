.App-logo {
  height: 200px;
  pointer-events: none;
}

.App-header {
  background-color: rgb(0,0,0);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.headline {
  margin-top: 0px;
  font-size: 40px;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-form {
  align-self: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 40px;
}

.form-element {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin: 10px 0px;
}

.form-element > input {
    width: 60%;
}

.submit-button {
  margin-top: 20px;
  align-self: flex-end;
  width: 300px;
}

.submit-button > button {
  margin-top: 20px;
  align-self: flex-end;
  width: 300px;
}

.button {
  display: inline-block;
  padding: 15px 25px;
  font-size: calc(10px + 1vmin);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(86,179,205);
  border: none;
  border-radius: 3px;
  box-shadow: 1px 1px 0px 0px #999;
}

.button:hover {background-color: rgb(109,186,198)}

.button:active {
  background-color: rgb(59,131,170);
  box-shadow: 0 2px rgb(79,94,99);
}

.instructions {
  padding: 10ps 50px;
  width: 70%;
  align-self: center;
  margin-bottom: 20px;
}

a {
  text-decoration: none;
}

p > span > a {
  text-decoration: none;
  color:rgb(59,131,170)
}

.footerContainer {
  width: 85%;
  align-self: center;
  background-color: white;
  margin-bottom: 50px;
}

.email-footer-wrapper {
  margin: 20px 0px 0px 10px;
}

.inner-wrapper {
  margin: 0px 0px 0px 0px;
}

.name-container {
  margin: 0px 0px 0px 6px;
}

.employee-name {
  font-weight: bolder;
  font-size:14px;
  color:rgb(43,99,123);
}

.job-title {
  font-weight: normal;
  font-size:14px;
}

.logo-table-cell {
  padding-bottom: 0;
  padding-top: 9px;
}

.company-name-table-cell {
  line-height: 36px;
  vertical-align: middle;
}

.company-name {
  font-size:12pt;
  font-weight: bold;
}

.contact-details {
  font-size:10pt;
  padding-left: 3px;
}

.cc-links {
  font-size: 10pt;
  margin-bottom: 0;
  padding-left: 3px;
}

.table-line-adress {
  line-height: 15px;
}
.adress-section > p {
  margin: 0px 0px;
}

.adress-section {
  margin-bottom: 10px;
}

.adress-postfix {
  color:rgb(79,94,99);
  font-size:9px;
}
.adress-line > a{
  font-size:11px;
  color:rgb(59,131,170);
}
.city-name {
  display: inline-block;
  width: 90px;
  font-size:11px;
}

.span-wrapper-elem {
  text-decoration: none;
  font-size:10pt;
  vertical-align: middle;
}

.footer-icon {
  margin-top: 10px;
}

.span-wrapper-elem > a {
  color:rgb(59,131,170);
}

.postfix {
  font-size:11px;
  max-width: 550px;
}
.cc-gray {
  color:rgb(79,94,99);
}
.cc-blue {
  color:rgb(59,131,170);
}
.black {
  color: rgb(0,0,0);
}
